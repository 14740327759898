import React from "react"

import styled from "styled-components"

import { ChevronForward } from "@kiwicom/orbit-components/icons"

const StyledChevronForward = styled(ChevronForward)`
  height: 20px;
  top: -1px;
  position: relative;
`

type CTALinkProps = {
  to?: string
  page?: string
  children: React.ReactNode
}

const CTALink = ({ to, children }: CTALinkProps) => (
  <a href={to}>
    {children}
    <StyledChevronForward />
  </a>
)

export default CTALink
