import React from "react"

import styled from "styled-components"

import { ButtonPrimitive } from "@kiwicom/orbit-components"

const IconWrapper = styled.div`
  margin-right: ${(props) => props.theme.orbit.spaceXXSmall};
  margin-bottom: 2px;
  > svg {
    fill: ${(props) => props.theme.orbit.colorIconSecondary};
  }
  :hover > svg {
    fill: ${(props) => props.theme.orbit.colorTextLinkSecondaryHover};
  }
`

interface InputSuffixedButtonProps
  extends React.ComponentProps<typeof ButtonPrimitive> {
  icon: React.ReactNode
}

const InputSuffixedButton = ({ icon, ...props }: InputSuffixedButtonProps) => (
  <ButtonPrimitive {...props}>
    <IconWrapper>{icon}</IconWrapper>
  </ButtonPrimitive>
)

export { InputSuffixedButton }
