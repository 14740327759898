import React, { ReactElement } from "react"

import moment from "moment"
import styled from "styled-components"

import Loading from "@kiwicom/orbit-components/lib/Loading"
import Stack from "@kiwicom/orbit-components/lib/Stack"

import { ServiceUpdateSummaryScope } from "types/service-update"

import { getDefaultTimezone } from "utils/date-utils"
import { capitalizeFirstLetter } from "utils/string-utils"

import { useServiceUpdateSummary } from "./service-update-hooks"

type ServiceUpdateWrapperType = {
  type: "info" | "warning" | "minorWarning" | "loading" | "error"
}

const ServiceUpdateWrapper = styled.div<ServiceUpdateWrapperType>`
  min-height: 47px;
  background: ${(props) =>
    props.type == "loading" || props.type == "error"
      ? "white"
      : props.type == "info"
      ? props.theme.orbit.paletteProductNormal
      : props.type == "minorWarning"
      ? props.theme.orbit.paletteBlueNormal
      : props.theme.orbit.paletteRedNormal};
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-between;
  border: 1px solid transparent;
  border-color: ${(props) =>
    (props.type == "loading" || props.type == "error") &&
    props.theme.orbit.borderColorCard};
  color: ${(props) =>
    props.type == "error"
      ? props.theme.orbit.colorTextError
      : props.type == "loading"
      ? props.theme.orbit.borderColorCard
      : "white"};
  padding: 10px 20px;
  margin: 0 auto;
  width: 100%;
  border-radius: ${(props) => props.theme.orbit.borderRadiusLarge};
`

const UpdateLink = styled.a`
  color: white;
  text-decoration: underline;
  white-space: nowrap;
  &:hover {
    opacity: 0.9;
  }
`

function describeScope(scope: ServiceUpdateSummaryScope, count: number) {
  if (scope && scope.type == "trip") {
    const date = moment.tz(
      scope.originallyScheduledDeparture,
      getDefaultTimezone()
    )
    const dayDescription = date.isSame(moment.tz(getDefaultTimezone()), "day")
      ? "the"
      : date.format("dddd") + "'s"
    return `${dayDescription} ${date.format("HH:mm")} service from ${
      scope.origin
    } to ${scope.destination}`
  } else {
    // Don't have to deal with plurals here - the one-trip case is handled
    // above.

    // Spell out the number of disrupted services - "2 services" sounds like
    // it might be a route number, but "two services" is unambiguous. Past
    // five there isn't really any point in giving an exact number.
    const numbers = { 2: "two", 3: "three", 4: "four", 5: "five" }
    const countWord = count <= 5 ? numbers[count] : "some"

    if (scope && scope.type == "route") {
      const routeDesc =
        scope.route[0] == scope.route[1]
          ? `within ${scope.route[0]}`
          : `between ${scope.route[0]} and ${scope.route[1]}`

      return `${countWord} services ${routeDesc}`
    } else {
      return `${countWord} services`
    }
  }
}

const ServiceUpdateSummary = () => {
  const { error, summary, summaryLoaded } = useServiceUpdateSummary()

  if (error) {
    return (
      <ServiceUpdateWrapper type="error">
        Couldn't get service updates: {error.message}
      </ServiceUpdateWrapper>
    )
  }

  if (!(summaryLoaded && summary)) {
    return (
      <ServiceUpdateWrapper type="loading">
        <Loading
          dataTest="loading-message"
          type="inlineLoader"
          text="Checking for service updates"
        ></Loading>
      </ServiceUpdateWrapper>
    )
  }

  let updateText: ReactElement | string
  let type: "info" | "warning" | "minorWarning"

  if (summary.type == "none") {
    type = "info"
    updateText = (
      <>
        <strong>Good service.</strong> All buses are running. You can track your
        bus on our live map.
      </>
    )
  } else if (summary.type == "custom") {
    type = "warning"
    updateText = summary.shortMessage
  } else {
    // automatic

    if (summary.disruptionTypes.includes("other")) {
      // at least one unclassified manual update - we just fall back to
      // "important updates", which is broad enough to encompass anything
      type = "warning"

      updateText = (
        <>
          <strong>Important update{summary.disruptionCount != 1 && "s"}</strong>{" "}
          affecting {describeScope(summary.scope, summary.disruptionCount)}.
        </>
      )
    } else if (
      summary.disruptionTypes.includes("delay") &&
      (summary.disruptionTypes.includes("part_cancellation") ||
        summary.disruptionTypes.includes("cancellation"))
    ) {
      type = "warning"

      updateText = (
        <>
          <strong>Delays and cancellations</strong> affecting{" "}
          {describeScope(summary.scope, summary.disruptionCount)}.
        </>
      )
    } else if (summary.disruptionTypes.includes("cancellation")) {
      type = "warning"

      updateText = (
        <>
          <strong>Cancellation{summary.disruptionCount != 1 && "s"}</strong>{" "}
          affecting {describeScope(summary.scope, summary.disruptionCount)}.
        </>
      )
    } else if (summary.disruptionTypes.includes("part_cancellation")) {
      type = "minorWarning"

      updateText = (
        <>
          <strong>Cancellation{summary.disruptionCount != 1 && "s"}</strong>{" "}
          affecting certain stops on{" "}
          {describeScope(summary.scope, summary.disruptionCount)}.
        </>
      )
    } else if (summary.disruptionTypes.includes("delay")) {
      // maxDelay will always be set when there's a delay in disruptionTypes
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const maxDelayMinutes = summary.maxDelayMinutes!

      // If only one or two delays, blue bar and "Good service" with a caveat.
      // Any more and "delays" is the headline message.
      if (summary.disruptionCount == 1) {
        type = "minorWarning"
        updateText = (
          <>
            <strong>Good service</strong> on most buses.{" "}
            {capitalizeFirstLetter(
              describeScope(summary.scope, summary.disruptionCount)
            )}{" "}
            is delayed by up to {maxDelayMinutes.toFixed(0)} minutes.
          </>
        )
      } else if (summary.disruptionCount == 2) {
        type = "minorWarning"
        updateText = (
          <>
            <strong>Good service</strong> on most buses. Delays of up to{" "}
            {maxDelayMinutes.toFixed(0)} minutes on{" "}
            {describeScope(summary.scope, summary.disruptionCount)}.
          </>
        )
      } else {
        type = "warning"
        updateText = (
          <>
            <strong>Delays</strong> of up to {maxDelayMinutes.toFixed(0)}{" "}
            minutes on {describeScope(summary.scope, summary.disruptionCount)}.
          </>
        )
      }
    } else {
      // should be unreachable, but just in case
      type = "warning"
      updateText = (
        <>
          <strong>Important updates</strong> affecting some services.
        </>
      )
    }
  }

  return (
    <ServiceUpdateWrapper data-test="service-update" type={type}>
      <Stack
        direction="column"
        spacing="XXSmall"
        tablet={{ direction: "row", justify: "between", spacing: "medium" }}
      >
        <div>{updateText}</div>
        {summary.type == "none" ? (
          <UpdateLink href="/map/">Live map</UpdateLink>
        ) : (
          <UpdateLink href="/service-updates/">Learn more</UpdateLink>
        )}
      </Stack>
    </ServiceUpdateWrapper>
  )
}

export default ServiceUpdateSummary
