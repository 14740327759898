import React, { Suspense, lazy } from "react"

import styled from "styled-components"

import Box from "@kiwicom/orbit-components/lib/Box"
import Heading from "@kiwicom/orbit-components/lib/Heading"
import Loading from "@kiwicom/orbit-components/lib/Loading"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import { useGlobalState } from "utils/state-utils"

const RouteMap = lazy(() =>
  import("./route-map").then((module) => ({ default: module.RouteMap }))
)

const RouteMapWrapper = styled.div`
  height: 450px;
  display: flex;
  flex-direction: column;
`

const RouteMapPlaceholder = styled.div`
  flex: 1;
  border: ${(props) => "1px solid " + props.theme.orbit.backgroundSeparator};
  background: ${(props) => props.theme.orbit.backgroundBody};
  padding: 40px 20px;
  text-align: center;
`

const HomepageRouteMap = () => (
  <RouteMap showService="all" showVehicles padding={60} />
)

const HomepageRouteMapOverall = () => {
  const { didUserScroll } = useGlobalState()

  return (
    <RouteMapWrapper>
      {didUserScroll ? (
        typeof navigator !== "undefined" &&
        navigator.userAgent.indexOf("Windows NT 5.") != -1 ? (
          <RouteMapPlaceholder>
            <Stack spacing="small">
              <Heading type="title3">
                The route map does not work on Windows XP
              </Heading>
              <Text type="secondary" align="center">
                Try using a different computer or loading the site on your phone
              </Text>
            </Stack>
          </RouteMapPlaceholder>
        ) : (
          <Suspense fallback={<div>Loading Map...</div>}>
            <HomepageRouteMap />
          </Suspense>
        )
      ) : (
        <RouteMapPlaceholder>
          <Box align="center">
            <Loading text="The route map is loading" />
          </Box>
        </RouteMapPlaceholder>
      )}
    </RouteMapWrapper>
  )
}

export default HomepageRouteMapOverall
